<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/logo_white.png" alt="logo">
                    </a>
                    <p>Ocena is a research firm that focuses on developing innovative responses to social challenges through Baseline Assessments, Midterm Evaluation, End of Project Evaluation, Operational Research, and Project Design.</p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>

                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about-us-1">About Us</a></li>
                        <li><a routerLink="/social-research">Social Research</a></li>
                        <li><a routerLink="/pricing">Pricing</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-2">
                    <h3>Opening Hours</h3>

                    <p>Monday - Friday: 9AM - 5PM</p>
                    <p>Saturday: 10AM - 2PM</p>
                    <p>Sunday: Closed</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Mogotio Rd, Westlands

                            Royal Offices, 1st Floor, Room 18A, Nairobi Kenya</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+254780058536">(+254) 0113 387 217</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@ocena.org">info@ocena.org</a></li>
                    </ul>
                </div>
            </div>

        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p *ngFor="let Text of copyrightText;"><i class='bx bx-copyright'></i>{{Text.text}} {{Text.linkText}}</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <!-- <li><a routerLink="privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="terms-of-service">Terms & Conditions</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape16"><img src="assets/img/shape/shape16.png" alt="image"></div>
</footer>
<!-- End Footer Area -->



<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>
