import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hometwo-main-banner',
  templateUrl: './hometwo-main-banner.component.html',
  styleUrls: ['./hometwo-main-banner.component.scss']
})
export class HometwoMainBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
