<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Sectors We Cover</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content">
                    <span class="sub-title"></span>
                    <div class="features-text">
                        <h4>Sectors We Cover</h4>

                    </div>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Agriculture</span></li>
                        <li><span><i class='bx bx-check'></i> Airline</span></li>
                        <li><span><i class='bx bx-check'></i> Alcohol</span></li>
                        <li><span><i class='bx bx-check'></i> Appreals</span></li>
                        <li><span><i class='bx bx-check'></i> Beauty</span></li>
                        <li><span><i class='bx bx-check'></i> Communication</span></li>
                        <li><span><i class='bx bx-check'></i> Education</span></li>
                        <li><span><i class='bx bx-check'></i> Entertainment</span></li>
                        <li><span><i class='bx bx-check'></i> Finance</span></li>
                        <li><span><i class='bx bx-check'></i> Food</span></li>
                        <li><span><i class='bx bx-check'></i> Government</span></li>
                        <li><span><i class='bx bx-check'></i> IT</span></li>
                        <li><span><i class='bx bx-check'></i> Media</span></li>
                        <li><span><i class='bx bx-check'></i> Mining</span></li>
                        <li><span><i class='bx bx-check'></i> Mother</span></li>
                        <li><span><i class='bx bx-check'></i> Motor</span></li>
                        <li><span><i class='bx bx-check'></i> Pharmacy</span></li>
                        <li><span><i class='bx bx-check'></i> Retail</span></li>
                        <li><span><i class='bx bx-check'></i> Sports</span></li>
                        <li><span><i class='bx bx-check'></i> Tobacco</span></li>
                        <li><span><i class='bx bx-check'></i> Travel</span></li>
                    </ul>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/services-img2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
