import { Component, OnInit } from '@angular/core';
import { ReinitapiService } from '../../../services/reinitapi.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth.guard';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent implements OnInit {
  Form: FormGroup;
  message: string;
  ClientName: any;
  error: string;
  constructor(private fb: FormBuilder, private Data: ReinitapiService, public authGuard: AuthGuard, private GetQuery: ActivatedRoute) {
    var ClientId = this.GetQuery.snapshot.params['id'];
    this.Data.GetClientById(ClientId).subscribe(res => {
      let ClientData = JSON.parse(JSON.stringify(res));
      this.ClientName = ClientData.clientName;
    })
  }

  public submitted = false;
  public loading = false;
  get clientname() {
    return this.Form.get('ClientName');
  }
  // get assignment() {
  //   return this.Form.get('Assignment');
  // }
  // get year() {
  //   return this.Form.get('Year');
  // }




  ngOnInit(): void {
    this.Form = this.fb.group({
      ClientName: ['', [Validators.required]],
    });
  }
  EditClient() {
    var ClientId = this.GetQuery.snapshot.params['id'];
    this.submitted = true
    console.log(this.Form.value);
    if (this.Form.invalid) {
      this.error = "Client Not Updated !"
      return
    }
    this.loading = true;
    var ClientData = {
      'ClientId': Number(ClientId),
      'ClientName': this.Form.value.ClientName,
    }
    this.Data.editClient(ClientData).subscribe(
      res => {
        this.loading = false,
          //this.message = res.success
          this.message = "Client Updated Succefully !"
      },
      err => {
        this.loading = false,
          //this.error= err.error.message
          this.error = "Client Not Updated !"
      }
    );
  }

}
