<div class="container">
    <div class="subscribe-content">
        <h2>Subscribe To Our Newsletter</h2>
        <p>We would also like to catch you up on all kinds of interesting news and events.</p>
        <form class="newsletter-form">
            <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
            <button type="submit" class="default-btn">Subscribe Now</button>
        </form>
    </div>
</div>
