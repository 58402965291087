<div class="digital-agency-banner">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <span class="sub-title wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">Social Research</span>
                    <h1 style="font-size: 40px;" class="wow fadeInLeft" data-wow-delay="10ms" data-wow-duration="1000ms"> Data Driven Results</h1>
                    <p class="wow banner-text fadeInLeft" style="font-size: 18px;" data-wow-delay="200ms" data-wow-duration="1000ms">

                        Ocena provides research services to institutions in the public and the private sectors.  We focus on developing
                        innovative responses towards social challenges through provision of baseline assessments, midterm and end of project evaluations, operational research,
                         and opinion polls.
                    </p>
                    <div class="btn-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1000ms">
                        <a routerLink="/about" class="default-btn">Get Started</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="banner-image">
                    <img src="assets/img/banner-img4.png" class="wow fadeInUp" alt="image" data-wow-delay="00ms" data-wow-duration="1000ms">
                </div>
            </div>

        </div>
    </div>
</div>
