<div class="container">
    <h2>Our Clients</h2>
    <div class="row align-items-center">
        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-67.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-68.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-69.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-70.png" alt="image">
            </div>
        </div>


        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-71.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-73.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-74.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-75.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-76.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-78.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-79.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-80.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-82.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-83.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-84.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-85.png" alt="image">
            </div>
        </div>

        <div class="col-lg-2 col-6 col-sm-4 col-md-4">
            <div class="single-partner-item">
                <img src="assets/img/partner/partner-86.png" alt="image">
            </div>
        </div>
    </div>
</div>
