import { Component, OnInit } from '@angular/core';
import { AuthGuard } from 'src/app/services/auth.guard';
import { ReinitapiService } from 'src/app/services/reinitapi.service';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})
export class AssignmentsComponent implements OnInit {
  consultancies: any;
  Clients: any;
  constructor(public authGuard: AuthGuard, public services: ReinitapiService) {
    this.services.getData().subscribe((data) => {
      this.consultancies = data;
    })
    this.services.getClient().subscribe((ClintData) => {
      this.Clients = ClintData;
    })
  }
  ngOnInit(): void {
  }

}
