<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Frequently Asked Questions</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: What is digital marketing?</h3>
                    <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: What does a digital marketer do?</h3>
                    <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: Would my business benefit from digital marketing?</h3>
                    <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: What’s the biggest mistake digital marketers make?</h3>
                    <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: How often should I update my website?</h3>
                    <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: What do I need to know about mobile?</h3>
                    <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: What is content marketing?</h3>
                    <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: Do I need a blog?</h3>
                    <p><strong>A:</strong> Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="project-start-area bg-f9f9f9 ptb-100">
    <app-project-start></app-project-start>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
</section>