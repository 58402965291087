import { Component, OnInit } from '@angular/core';
import { ReinitapiService } from '../../../services/reinitapi.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth.guard';


@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.scss']
})
export class UpdateClientComponent implements OnInit {
  Clients: any;
  clientId: any;
  Years: object;
  Year: any;
  Form: FormGroup;
  LoginData: any;
  constructor(private fb: FormBuilder, private Data: ReinitapiService, public authGuard: AuthGuard, private router: Router) {
    this.Data.getClient().subscribe((ClintData) => {
      this.Clients = ClintData;
    })
    var today = new Date();
    var year = today.getFullYear();
    var pastfifthYear = year - 5;
    var allyears = [];
    for (var i = pastfifthYear; i <= pastfifthYear + 10; i++) {
      allyears.push(i);
    }
    this.Years = allyears;
  }
  public submitted = false;
  public loading = false;
  message: string;
  error: string;


  get clientid() {
    return this.Form.get('ClientId');
  }
  get assignment() {
    return this.Form.get('Assignment');
  }
  get year() {
    return this.Form.get('Year');
  }

  ngOnInit(): void {
    this.Form = this.fb.group({
      ClientId: ['', [Validators.required, Validators.pattern("[0-9]*")]],
      Assignment: ['', [Validators.required]],
      Year: ['', [Validators.required, Validators.pattern("[0-9]*")]],
    });
  }


  SetId(e) {
    this.clientId = e.target.value;
  }
  SetYear(e) {
    this.Year = Number(e.target.value);
  }
  UpdateClient() {
    this.submitted = true
    console.log(this.Form.value);
    console.log(this.Form.value.Assignment);
    console.log(this.Form.value.ClientId);
    console.log(this.Form.value.Year);
    if (this.Form.invalid) {
      if (this.Form.value.ClientId == '') {
        this.error = "Client name is required!"
      }
      else if (this.Form.value.Assignment == '') {
        this.error = "Assignment is required!"
      }
      else if (this.Form.value.Year == '') {
        this.error = "Year is required!"
      }
      return
    }
    this.loading = true;
    var ClientData = {
      'ClientId': Number(this.clientId),
      'Assignment': this.Form.value.Assignment,
      'Year': this.Year
    }
    this.Data.updateClient(ClientData).subscribe(
      res => {
        this.loading = false,
          //this.message = res.success
          this.message = "Assignment Added Succefully !"
        this.Form.reset()
      },
      err => {
        this.loading = false,
          //this.error= err.error.message
          this.error = "Assignment Not Added !"
      }
    );
  }
}
