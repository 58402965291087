<app-homethree-main-banner></app-homethree-main-banner>

<section class="partner-area bg-f9f9f9 ptb-70">
    <app-partner></app-partner>
</section>

<section class="featured-services-area pt-100 pb-70">
    <app-featured-services></app-featured-services>
</section>

<app-homethree-about></app-homethree-about>

<app-homethree-services></app-homethree-services>

<section class="seo-analysis-area ptb-100">
    <app-seo-analysis></app-seo-analysis>
</section>

<section class="feedback-area ptb-100">
    <app-feedback></app-feedback>
</section>

<app-homethree-portfolio></app-homethree-portfolio>

<app-how-its-work></app-how-its-work>

<section class="team-area-two ptb-100">
    <app-team-style-two></app-team-style-two>
</section>

<section class="blog-area bg-f9f9f9 pt-100 pb-70">
    <app-blog></app-blog>
</section>

<section class="subscribe-area ptb-100">
    <app-subscribe></app-subscribe>

    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>
