<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Services Details</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content">
                    <span class="sub-title">Digital Marketing</span>
                    <h2>Creative Solutions, Creative Results</h2>
                    <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>
                    <div class="features-text">
                        <h4>Core Development</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    </div>
                    <div class="features-text">
                        <h4>Define Your Choices</h4>
                        <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                    </div>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/services-img1.png" alt="image">
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/services-img2.png" alt="image">
                </div>
            </div>
            
            <div class="overview-content">
                <div class="content right-content">
                    <span class="sub-title">Social Media Marketing</span>
                    <h2>Solving Problems, Building Brands</h2>
                    <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check'></i> Recommender Systems</span></li>
                        <li><span><i class='bx bx-check'></i> Demand Prediction</span></li>
                        <li><span><i class='bx bx-check'></i> Omnichannel Analytics</span></li>
                        <li><span><i class='bx bx-check'></i> Lead Generation</span></li>
                        <li><span><i class='bx bx-check'></i> Dedicated Developers</span></li>
                        <li><span><i class='bx bx-check'></i> 24/7 Support</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Digital Marketing</span>
            <h2>Core Business Areas</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-content"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3>Informative Content</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-darts"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3>Visitor Growth</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-bar-chart"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3>Google Analytics</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-megaphone"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3>Innovative Strategies</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-link"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3>Link Building</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-reputation"></i>
                        <div class="circles-box">
                            <div class="circle-one"></div>
                            <div class="circle-two"></div>
                        </div>
                    </div>
                    <h3>Reputation Management</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="seo-analysis-area ptb-100">
    <app-seo-analysis></app-seo-analysis>
</section>

<section class="pricing-area bg-f9f9f9 pt-100 pb-70">
    <app-pricing></app-pricing>
</section>

<section class="project-start-area ptb-100">
    <app-project-start></app-project-start>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
</section>