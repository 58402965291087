import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ReinitapiService } from './reinitapi.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: ReinitapiService, private router: Router) {

  }
  canActivate() {
    if (this.auth.IsLoggedIn()) {
      return true;
    }
    else {
      this.router.navigate(['admin/login']);
      return false;
    }

  }

  autoLogOut(expirationTime: number) {
    setTimeout(() => {
      this.LogOut()
    }, expirationTime);
  }

  LogOut() {
    localStorage.removeItem('accessToken');
    this.router.navigate(['admin/login']);
  }

}
