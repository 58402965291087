import { Component, OnInit } from '@angular/core';
import { ReinitapiService } from '../../../services/reinitapi.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {


  Form: FormGroup;
  constructor(private fb: FormBuilder, private service: ReinitapiService) { }
  public submitted = false;
  public loading = false;
  message: string;
  error: string;

  get subj() {
    return this.Form.get('subject');
  }

  get name() {
    return this.Form.get('name');
  }

  get mess() {
    return this.Form.get('message');
  }

  get email() {
    return this.Form.get('email');
  }

  ngOnInit() {
    this.Form = this.fb.group({
      name: ['', [Validators.required, Validators.pattern("[a-zA-Z ]*")]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
      to_email: ['info@ocena.org']
    });
  }

  submitForm() {
    this.submitted = true

    if (this.Form.invalid) {
      return
    }
    this.loading = true;
    this.service.contactUs(this.Form.value).subscribe(
      res => {
        this.loading = false,
          //this.message = res.success
          this.message = "Mail Sent !"
        this.Form.reset()
      },
      err => {
        this.loading = false,
          //this.error= err.error.message
          this.error = "Error !"
        console.log(err)
      }
    );
  }

}
