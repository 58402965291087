<section class="portfolio-area section-title-center pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Case Studies</span>
            <h2>Check of Our Some Recent Works & Case Studies</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-portfolio-box">
                    <a routerLink="/single-portfolio" class="image d-block">
                        <img src="assets/img/portfolio/portfolio-img1.jpg" alt="image">
                    </a>

                    <div class="content">
                        <h3><a routerLink="/single-portfolio">Movie Recommendation</a></h3>
                        <span>System Project</span>
                        <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-portfolio-box">
                    <a routerLink="/single-portfolio" class="image d-block">
                        <img src="assets/img/portfolio/portfolio-img2.jpg" alt="image">
                    </a>

                    <div class="content">
                        <h3><a routerLink="/single-portfolio">Customer Segmentation</a></h3>
                        <span>Machine Learning</span>
                        <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-portfolio-box">
                    <a routerLink="/single-portfolio" class="image d-block">
                        <img src="assets/img/portfolio/portfolio-img3.jpg" alt="image">
                    </a>

                    <div class="content">
                        <h3><a routerLink="/single-portfolio">Data Analysis</a></h3>
                        <span>Web Project</span>
                        <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-portfolio-box">
                    <a routerLink="/single-portfolio" class="image d-block">
                        <img src="assets/img/portfolio/portfolio-img4.jpg" alt="image">
                    </a>

                    <div class="content">
                        <h3><a routerLink="/single-portfolio">Detection Project</a></h3>
                        <span>Programming</span>
                        <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-portfolio-box">
                    <a routerLink="/single-portfolio" class="image d-block">
                        <img src="assets/img/portfolio/portfolio-img5.jpg" alt="image">
                    </a>

                    <div class="content">
                        <h3><a routerLink="/single-portfolio">Data Scientist</a></h3>
                        <span>Data Science</span>
                        <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-portfolio-box">
                    <a routerLink="/single-portfolio" class="image d-block">
                        <img src="assets/img/portfolio/portfolio-img6.jpg" alt="image">
                    </a>

                    <div class="content">
                        <h3><a routerLink="/single-portfolio">Benefits Research</a></h3>
                        <span>Science Projects</span>
                        <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>