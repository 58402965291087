import { Component, OnInit } from '@angular/core';
import { ReinitapiService } from '../../../services/reinitapi.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth.guard';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit {
  Form: FormGroup;
  LoginData: any;
  constructor(private fb: FormBuilder, private Data: ReinitapiService, private router: Router, public authGuard: AuthGuard) { }
  public submitted = false;
  public loading = false;
  message: string;
  error: string;


  get clientname() {
    return this.Form.get('ClientName');
  }
  // get assignment() {
  //   return this.Form.get('Assignment');
  // }
  // get year() {
  //   return this.Form.get('Year');
  // }


  ngOnInit(): void {
    this.Form = this.fb.group({
      ClientName: ['', [Validators.required]],
    });
  }

  CreateClient() {
    this.submitted = true

    if (this.Form.invalid) {
      this.error = "Client name required!"
      return
    }
    this.loading = true;
    var ClientData = {
      'ClientName': this.Form.value.ClientName,
      // 'Assignment': this.Form.value.Assignment,
      // 'Year': this.Form.value.Year
    }
    this.Data.createClient(ClientData).subscribe(
      res => {
        this.loading = false,
          //this.message = res.success  
          this.message = "Client Added Succefully !"
        this.Form.reset()
      },
      err => {
        this.loading = false,
          //this.error= err.error.message
          this.error = "Client Not Added !"
      }
    );
  }


}
