import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicespage-two',
  templateUrl: './servicespage-two.component.html',
  styleUrls: ['./servicespage-two.component.scss']
})
export class ServicespageTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
