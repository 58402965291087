import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operationl-research',
  templateUrl: './operationl-research.component.html',
  styleUrls: ['./operationl-research.component.scss']
})
export class OperationlResearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
