<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Consultancies</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="cart-area ptb-100 tableBrdrcustom tableCons">
    <div class="container">
        <form>
            <div class="cart-table1 table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="min-width: 300px;max-width: 300px;">Client
                            </th>
                            <th style="min-width: 650px;max-width: 650px;">
                                Assignment</th>
                            <th style="min-width: 250px;max-width: 250px;"> Year
                            </th>
                            <!-- <th colspan="2" style="padding:0;">
                                <table style="width: 100%;">
                                    <tr>
                                        <th> Assignment</td>
                                        <th> Year</td>
                                    </tr>
                                </table>
                            </th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let client of Clients">
                            <td style="min-width: 300px;max-width: 300px;">
                                {{client.clientName}} </td>
                            <td colspan="3" style="min-width:800px;max-width: 800px;padding: 0;">
                                <table>
                                    <tr *ngFor="let consultancy of consultancies">
                                        <ng-container *ngIf="client.clientId==consultancy.clientId">
                                            <td style="min-width: 650px;max-width: 650px;">
                                                {{consultancy.assignment}}</td>
                                            <td style="min-width: 250px;max-width: 250px;">
                                                {{consultancy.year}}</td>
                                        </ng-container>
                                    </tr>
                                </table>
                            </td>
                        </tr>


                        <!-- <tr *ngFor="let client of Clients" style="font-size: 10px;">
                            <td class="product-thumbnail">
                                <p style="color: black; font-weight: 600;">
                                    {{client.clientName}}
                                </p>
                            </td>&nbsp;&nbsp;
                            <div *ngFor="let consultancy of consultancies">
                                <div *ngIf="client.clientId==consultancy.clientId">
                                    <td class="product-price">
                                        <span class="unit-amount" style="font-size: 13px;">
                                            {{consultancy.assignment}}
                                        </span>
                                    </td>&nbsp;&nbsp;

                                    <td class="product-name">
                                        <p>{{consultancy.year}}</p>
                                    </td>
                                </div>

                            </div>
                        </tr> -->
                    </tbody>
                </table>
            </div>
        </form>
    </div>
</section>