<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Portfolio Details</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="portfolio-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="portfolio-details-image">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <img src="assets/img/portfolio/portfolio-img1.jpg" alt="image">
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <img src="assets/img/portfolio/portfolio-img2.jpg" alt="image">
                        </div>
                    </div>
                </div>

                <div class="portfolio-details-desc">
                    <span class="sub-title">Digital Marketing</span>
                    <h3>Customer Satisfaction for a Digital Marketing Agency</h3>
                    <p>Improve Machine Learning algorithms by studying underfitting, overfitting, training, validation, n-fold cross validation, testing, and how hyperparameters could improve performance. Perform linear and logistic regressions in Python.</p>

                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="image">
                                <img src="assets/img/portfolio/portfolio-img3.jpg" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="content">
                                <h3>Important Facts</h3>
                                <ul>
                                    <li>The Field of Data Science</li>
                                    <li>The Problem</li>
                                    <li>The Solution</li>
                                    <li>The Skills</li>
                                    <li>Statistics</li>
                                    <li>Mathematics</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>

                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden in the middle of text.</p>
                    <h3>Results</h3>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna ad aliqua. Ut enim ad minim veniam quis nostrud exercitation ullamco labori.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="portfolio-sidebar-sticky">
                    <div class="portfolio-details-info">
                        <ul>
                            <li>
                                <div class="icon">
                                    <i class='bx bx-user-pin'></i>
                                </div>
                                <span>Client:</span>
                                <a href="https://envytheme.com/" target="_blank">EnvyTheme.com</a>
                                <a href="#" target="_blank">ThemeForest.com</a>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class='bx bx-map'></i>
                                </div>
                                <span>Location:</span>
                                New York, USA
                            </li>
                            <li>
                                <div class="icon">
                                    <i class='bx bx-purchase-tag'></i>
                                </div>
                                <span>Technologies:</span>
                                Python, Data Science
                            </li>
                            <li>
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <span>Completed:</span>
                                28 April 2020
                            </li>
                            <li>
                                <div class="icon">
                                    <i class='bx bx-globe'></i>
                                </div>
                                <span>Website:</span>
                                <a href="https://envytheme.com/" target="_blank">EnvyTheme.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="portfolio-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>More Projects You Might Like</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio-item">
                    <a routerLink="/single-portfolio" class="image d-block">
                        <img src="assets/img/portfolio/portfolio-img1.jpg" alt="image">
                    </a>

                    <div class="content">
                        <h3><a routerLink="/single-portfolio">Customer Satisfaction for a Digital Marketing Agency</a></h3>
                        <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio-item">
                    <a routerLink="/single-portfolio" class="image d-block">
                        <img src="assets/img/portfolio/portfolio-img2.jpg" alt="image">
                    </a>

                    <div class="content">
                        <h3><a routerLink="/single-portfolio">Return on Investment for Various Digital Marketing</a></h3>
                        <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-portfolio-item">
                    <a routerLink="/single-portfolio" class="image d-block">
                        <img src="assets/img/portfolio/portfolio-img3.jpg" alt="image">
                    </a>

                    <div class="content">
                        <h3><a routerLink="/single-portfolio">Google Search Engine Marketing Case Study</a></h3>
                        <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>