<section class="page-title-area">


    <div class="container">
        <div class="page-title-content">
            <h1>About Ocena</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about-img2.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="text">
                        <span class="sub-title">About Us</span>
                        <!-- <h2>Empowering Business With Information</h2> -->
                        <h2>Data Driven Results</h2>
                        <p>Ocena provides research services to institutions in the public and the private sectors. At Ocena we focus on developing innovative responses towards
                            social challenges through provision of baseline assessments, midterm and end of project evaluations, operational research, and opinion polls.</p>
                        <p>At Ocena, we work alongside policy-makers and program implementers to design, plan, train, implement, evaluate and report based on the evidence we gather from
                            research, monitoring and evaluation. As a research firm, we are inspired by the pursuit of integrity, excellence, accountability,collaboration and
                             the passion for doing things differently with a lasting positive impact on the individuals, businesses and communities.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-history></app-history>

<section class="team-area-two ptb-100">
    <app-team-style-two></app-team-style-two>

    <div class="shape17"><img src="assets/img/shape/shape17.png" alt="image"></div>
</section>


<section class="partner-area bg-f9f9f9 ptb-70">
    <app-partner></app-partner>
</section>
