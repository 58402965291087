<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-team-box">
                <img src="assets/img/team/team-img1.jpg" alt="team-image">

                <div class="content">
                    <h3>James Anderson</h3>
                    <span>CEO</span>
                </div>

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-team-box">
                <img src="assets/img/team/team-img2.jpg" alt="team-image">

                <div class="content">
                    <h3>Sarah Taylor</h3>
                    <span>Marketing Lead</span>
                </div>

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="section-title">
                <span class="sub-title">Our Team</span>
                <h2>We Help to Acheive Your Business Goal</h2>
                <a routerLink="team-1" class="learn-more-btn"><i class="left-icon flaticon-next-button"></i> View All <i class="right-icon flaticon-next-button"></i></a>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-team-box">
                <img src="assets/img/team/team-img3.jpg" alt="team-image">

                <div class="content">
                    <h3>Taylor Sopia</h3>
                    <span>Web Designer</span>
                </div>

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-team-box">
                <img src="assets/img/team/team-img4.jpg" alt="team-image">

                <div class="content">
                    <h3>Harry Steve</h3>
                    <span>Web Developer</span>
                </div>

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-team-box">
                <img src="assets/img/team/team-img5.jpg" alt="team-image">

                <div class="content">
                    <h3>Alina Smith</h3>
                    <span>Advisor</span>
                </div>

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="single-team-box">
                <img src="assets/img/team/team-img6.jpg" alt="team-image">

                <div class="content">
                    <h3>David Warner</h3>
                    <span>Support</span>
                </div>

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>