
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config';

@Injectable({
  providedIn: 'root'
})
export class ReinitapiService {

  appsettingData: any;
  readonly _BaseUri: any;
  readonly _ApiKey: any;

  constructor(private http: HttpClient, private config: ConfigService) {
    this.appsettingData = config.loadJSON('./assets/appsettings.json');
    this._BaseUri = this.appsettingData._baseUrl;
    this._ApiKey = this.appsettingData._apiKey;
  }

  IsLoggedIn() {
    return !!localStorage.getItem('accessToken');
  }

  contactUs(data: any) {
    let Headers = new HttpHeaders({
      'apikey': this._ApiKey,
    });
    return this.http.post<any>(this._BaseUri + '/send/message', data, { headers: Headers });
  }

  // Get Consultancies details
  getData() {
    let Headers = new HttpHeaders({
      'apikey': this._ApiKey,
    });
    return this.http.get(this._BaseUri + '/GetConsultancies', { headers: Headers });
  }

  // Get Client Detials
  getClient() {
    let Headers = new HttpHeaders({
      'apikey': this._ApiKey,
    });
    return this.http.get(this._BaseUri + '/GetClients', { headers: Headers });
  }

  login(data: any) {
    let Headers = new HttpHeaders({
      'apikey': this._ApiKey,
    });
    return this.http.post<any>(this._BaseUri + '/ocenaAdminLogin', data, { headers: Headers });
  }

  // Create New Client 
  createClient(data: any) {
    let Headers = new HttpHeaders({
      'apikey': this._ApiKey,
    });
    return this.http.post(this._BaseUri + '/createClient', data, { headers: Headers });
  }

  // Update Existing Client Details
  updateClient(data: any) {
    let Headers = new HttpHeaders({
      'apikey': this._ApiKey,
    });
    return this.http.post(this._BaseUri + '/updateClient', data, { headers: Headers });
  }


  GetClientById(clientId: any) {
    let Headers = new HttpHeaders({
      'apikey': this._ApiKey,
    });
    return this.http.get(this._BaseUri + '/getClientById?clientId=' + clientId, { headers: Headers });
  }

  editClient(ClientData: any) {
    let Headers = new HttpHeaders({
      'apikey': this._ApiKey,
    });
    return this.http.post(this._BaseUri + '/editClient', ClientData, { headers: Headers });
  }

  GetAssignmentById(id: any) {
    let Headers = new HttpHeaders({
      'apikey': this._ApiKey,
    });
    return this.http.get(this._BaseUri + '/getAssignmentById?id=' + id, { headers: Headers });
  }

  editAssignment(AssignmentData: any) {
    let Headers = new HttpHeaders({
      'apikey': this._ApiKey,
    });
    return this.http.post(this._BaseUri + '/editAssignment', AssignmentData, { headers: Headers });
  }


}

