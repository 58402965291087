<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Qualitative Social Research</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content">
                    <span class="sub-title"></span>
                    <h2>Operational Research</h2>
                    <p>Operations Research is gaining importance in programmes globally. At Ocena, we offer solutions that guide the programme implementation
                         to achieve best results by utilizing a vast range of qualitative and quantitative tools, to produce significant results worth applying and testing
                          in the real field. Through operations research, we identify problems and test the feasible solutions for them.
                    </p>
                    <!-- <div class="features-text">
                        <h4>Our Qualitative Social Research Methods</h4>
                        <p>We use wide range of qualittative social research methods and techniques that result into quality responses.</p>
                    </div> -->
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/services-img2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="project-start-area ptb-100">
    <app-project-start></app-project-start>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
</section>
