<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Quantitative Social Research</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content">
                    <span class="sub-title"></span>
                    <h2>Successful Marketing Campaigns</h2>
                    <p>We empower our clients with information that helps them make important decisions by identifying factors that drive customer loyalty,
                         understanding relationships/engagements and all forms of ad-hoc research into brands, products and services.</p>

                         <div class="features-text">
                            <h4>Our Quantitative Social Research Techniques</h4>
                            <p>We use wide range of quantittative social research techniques to collect data from the respondents.</p>
                        </div>

                         <ul class="features-list">
                            <li><span><i class='bx bx-check'></i> Computer Aided Web Interviews</span></li>
                            <li><span><i class='bx bx-check'></i> Computer Aided Telephonic Interviews</span></li>
                            <li><span><i class='bx bx-check'></i> Computer Aided Personal Interviews</span></li>
                            <li><span><i class='bx bx-check'></i> Mall Intercepts</span></li>
                            <li><span><i class='bx bx-check'></i> Diary Studies</span></li>
                            <li><span><i class='bx bx-check'></i> Tracker Studies</span></li>
                        </ul>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/services-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="project-start-area ptb-100">
    <app-project-start></app-project-start>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
</section>
