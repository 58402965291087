<div class="container">
    <div class="section-title">
        <span class="sub-title">Case Studies</span>
        <h2>Check of Our Some Recent Works & Case Studies</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
    </div>
</div>

<div class="container-fluid">
    <div class="portfolio-slides owl-carousel owl-theme">
        <div class="single-portfolio-item">
            <a routerLink="/single-portfolio" class="image d-block">
                <img src="assets/img/portfolio/portfolio-img1.jpg" alt="image">
            </a>

            <div class="content">
                <h3><a routerLink="/single-portfolio">Customer Satisfaction for a Digital Marketing Agency</a></h3>
                <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
            </div>
        </div>

        <div class="single-portfolio-item">
            <a routerLink="/single-portfolio" class="image d-block">
                <img src="assets/img/portfolio/portfolio-img2.jpg" alt="image">
            </a>

            <div class="content">
                <h3><a routerLink="/single-portfolio">Return on Investment for Various Digital Marketing Strategies</a></h3>
                <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
            </div>
        </div>

        <div class="single-portfolio-item">
            <a routerLink="/single-portfolio" class="image d-block">
                <img src="assets/img/portfolio/portfolio-img3.jpg" alt="image">
            </a>

            <div class="content">
                <h3><a routerLink="/single-portfolio">Google Search Engine Marketing Case Study Analysis</a></h3>
                <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
            </div>
        </div>

        <div class="single-portfolio-item">
            <a routerLink="/single-portfolio" class="image d-block">
                <img src="assets/img/portfolio/portfolio-img4.jpg" alt="image">
            </a>

            <div class="content">
                <h3><a routerLink="/single-portfolio">Analysis of New Product Launch Using Google Double Click</a></h3>
                <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
            </div>
        </div>

        <div class="single-portfolio-item">
            <a routerLink="/single-portfolio" class="image d-block">
                <img src="assets/img/portfolio/portfolio-img5.jpg" alt="image">
            </a>

            <div class="content">
                <h3><a routerLink="/single-portfolio">Social Media Strategies for Online Shopping Cart</a></h3>
                <a routerLink="/single-portfolio" class="link-btn"><i class="flaticon-next-button"></i></a>
            </div>
        </div>
    </div>
</div>