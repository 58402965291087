<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="what-we-do-content">
                <span class="sub-title">What We Do</span>
                <h2>We Help to Website Growth With Next Level Visitor</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <div class="skills-item">
                    <div class="skills-header">
                        <h4 class="skills-title">Digital Marketing & SEO</h4>
                        <div class="skills-percentage">
                            <div class="count-box">95%</div>
                        </div>
                    </div>
                    <div class="skills-bar">
                        <div class="bar-inner"><div class="bar progress-line" data-width="95"></div></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="what-we-do-content-accordion">
                <div class="accordion" id="whatWeDoAccordion">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <button type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Social Media Optimization
                                <i class="flaticon-minus"></i>
                            </button>
                        </div>

                        <div id="collapseOne" class="collapse show" data-parent="#whatWeDoAccordion">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Content Generation
                                <i class="flaticon-minus"></i>
                            </button>
                        </div>

                        <div id="collapseTwo" class="collapse" data-parent="#whatWeDoAccordion">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Pay Per Click Services
                                <i class="flaticon-minus"></i>
                            </button>
                        </div>

                        <div id="collapseThree" class="collapse" data-parent="#whatWeDoAccordion">
                            <div class="card-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>