import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-no-space-page',
  templateUrl: './portfolio-no-space-page.component.html',
  styleUrls: ['./portfolio-no-space-page.component.scss']
})
export class PortfolioNoSpacePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
