<section class="services-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Ocena</span>
            <h2>Social Research At A Glance</h2>
            <!-- <p>Ocena connects you with one of the largest consumer audience in Africa.</p> -->
        </div>

        <div class="row text-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <img class="img-social" src="assets/img/shape/icon1.svg" alt="">
                        <h3><a >Operational Research</a></h3>
                        <p style="color:black">Ocena, we offer solutions that guide the programme implementation to achieve best results by utilizing a vast range of
                            qualitative and quantitative tools</p>
                            <a routerLink="/operational-research" class="btn butn-learn">Learn More</a>
                    </div>
                </div>


            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <img class="img-social" src="assets/img/shape/icon2.svg" alt="">

                    <h3><a>Baseline Assessments</a></h3>
                    <p style="color:black">A baseline assessment is a crucial element in formative campaign research and planning, and in any monitoring and evaluation framework.</p>
                    <a  routerLink="/baseline-assessments" class="btn  butn-learn">Learn More</a>
                
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <img class="img-social" src="assets/img/shape/icon3.svg" alt="">
                    <h3><a >Midterm Evaluation</a></h3>
                    <p style="color:black">We endeavor to establish whether a project is heading towards the set goals and objectives, thereafter informing management early enough.</p>
                    <a routerLink="/midterm-evaluation" class="btn  butn-learn">Learn More</a>
               
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-services-box">
                    <img class="img-social" src="assets/img/shape/icon4.svg" alt="">
                    <h3><a >End of Project Valuation</a></h3>
                    <p style="color:black">We establish project outputs and immediate outcomes, with results of the evaluation compared to the results at baseline and midline.</p>
                    <a routerLink="/end-of-project-valuation" class="btn  butn-learn">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>
