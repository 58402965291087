<section class="banner-wrapper-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="banner-wrapper-content">
                    <div class="content">
                        <span class="sub-title">Growth Your Business</span>
                        <h1>We Are Full Service SEO Agency</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <form>
                            <div class="row">
                                <div class="col-lg-5 col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Website URL">
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-3 col-md-4 offset-lg-0 offset-md-4">
                                    <button type="submit" class="default-btn">Start Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="banner-wrapper-image">
                    <img src="assets/img/banner-img8.jpg" alt="image">
                    <div class="divider"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape/shape14.png" alt="image"></div>
</section>