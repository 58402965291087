<section class="about-area pb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about-img2.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="text">
                        <span class="sub-title">About Us</span>
                        <h2>Enjoy Full-Service Digital Marketing Expertise</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <ul class="funfacts-list">
                            <li>
                                <div class="list">
                                    <i class="flaticon-menu-1"></i>
                                    <h3 class="odometer" data-count="376">00</h3>
                                    <p>Completed projects</p>
                                </div>
                            </li>
                            <li>
                                <div class="list">
                                    <i class="flaticon-web-settings"></i>
                                    <h3 class="odometer" data-count="7548">00</h3>
                                    <p>Working hours were spent</p>
                                </div>
                            </li>
                            <li>
                                <div class="list">
                                    <i class="flaticon-conversation"></i>
                                    <h3 class="odometer" data-count="350">00</h3>
                                    <p>Expert team members</p>
                                </div>
                            </li>
                        </ul>
                        <a routerLink="/about-us-1" class="default-btn">More About Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>