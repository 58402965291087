<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Blog Right Sidebar</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="blog-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area widget-left-sidebar">
                    <section class="widget widget_search">
                        <h3 class="widget-title">Search</h3>

                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_plor_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 10, 2020</span>
                                <h4 class="title usmall"><a routerLink="/single-blog">The Data Surrounding Higher Education</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 21, 2020</span>
                                <h4 class="title usmall"><a routerLink="/single-blog">Conversion Rate the Sales Funnel Optimization</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 30, 2020</span>
                                <h4 class="title usmall"><a routerLink="/single-blog">Business Data is changing the world’s Energy</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/blog-2">Design <span class="post-count">(03)</span></a></li>
                            <li><a routerLink="/blog-2">Lifestyle <span class="post-count">(05)</span></a></li>
                            <li><a routerLink="/blog-2">Script <span class="post-count">(10)</span></a></li>
                            <li><a routerLink="/blog-2">Device <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/blog-2">Tips <span class="post-count">(01)</span></a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/blog-2">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-2">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-2">Aike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-2">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-2">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-2">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-2">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-2">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>

                    <section class="widget widget_instagram">
                        <ul>
                            <li>
                                <div class="box">
                                    <img src="assets/img/team/team-img1.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>

                            <li>
                                <div class="box">
                                    <img src="assets/img/team/team-img2.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>

                            <li>
                                <div class="box">
                                    <img src="assets/img/team/team-img3.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>

                            <li>
                                <div class="box">
                                    <img src="assets/img/team/team-img4.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>

                            <li>
                                <div class="box">
                                    <img src="assets/img/team/team-img5.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>

                            <li>
                                <div class="box">
                                    <img src="assets/img/team/team-img6.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>

                            <li>
                                <div class="box">
                                    <img src="assets/img/team/team-img7.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>

                            <li>
                                <div class="box">
                                    <img src="assets/img/team/team-img8.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>

                            <li>
                                <div class="box">
                                    <img src="assets/img/team/team-img9.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                        </ul>
                    </section>
                </aside>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                        <div class="single-blog-post">
                            <div class="image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog-img1.jpg" alt="image">
                                </a>
                            </div>

                            <div class="content">
                                <h3><a routerLink="/single-blog">Digital Marketing Agency Blogs You Should Read</a></h3>
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" alt="image">
                                    <div class="info">
                                        <h5>David Smith</h5>
                                        <span>Jun 21, 2020</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-6">
                        <div class="single-blog-post">
                            <div class="image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog-img2.jpg" alt="image">
                                </a>
                            </div>

                            <div class="content">
                                <h3><a routerLink="/single-blog">Digital Marketing Strategies for Lead Generation</a></h3>
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user2.jpg" alt="image">
                                    <div class="info">
                                        <h5>Sarah Taylor</h5>
                                        <span>Jun 20, 2020</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-6">
                        <div class="single-blog-post">
                            <div class="image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog-img3.jpg" alt="image">
                                </a>
                            </div>

                            <div class="content">
                                <h3><a routerLink="/single-blog">Agencies Can Successfully Recover From COVID?</a></h3>
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="info">
                                        <h5>Steven Gibson</h5>
                                        <span>Jun 19, 2020</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-6">
                        <div class="single-blog-post">
                            <div class="image">
                                <a routerLink="/single-blog" class="d-block">
                                    <img src="assets/img/blog/blog-img4.jpg" alt="image">
                                </a>
                            </div>

                            <div class="content">
                                <h3><a routerLink="/single-blog">Preparing E-Commerce for the Post-COVID Bounce Back</a></h3>
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" alt="image">
                                    <div class="info">
                                        <h5>David Smith</h5>
                                        <span>Jun 21, 2020</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area text-center">
                            <a routerLink="/blog-4" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/blog-4" class="page-numbers">2</a>
                            <a routerLink="/blog-4" class="page-numbers">3</a>
                            <a routerLink="/blog-4" class="page-numbers">4</a>
                            <a routerLink="/blog-4" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>