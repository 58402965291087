<div class="{{navbarClass}}">
    <div class="plor-responsive-nav">
        <div class="container-fluid">
            <div class="plor-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/logo.png" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="plor-nav">
        <div class="{{containerClass}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo_white-full.png" alt="logo" class="bfrSticky">
                    <img src="assets/img/logo.png" alt="logo" class="aftrSticky">
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Services <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/operational-research" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Operational
                                        Research</a></li>
                                <li class="nav-item"><a routerLink="/baseline-assessments" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Baseline
                                        Assessments</a></li>
                                <li class="nav-item"><a routerLink="/midterm-evaluation" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Midterm
                                        Evaluation</a></li>
                                <li class="nav-item"><a routerLink="/end-of-project-valuation" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">End of
                                        Project Valuation</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/consultancies" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Consultancies</a></li>
                        <!-- <li class="nav-item"><a routerLink="/our-data-collection-methods" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Data Collection</a></li>
                        <li class="nav-item"><a routerLink="/our-coverage" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Our Coverage</a></li>
                        <li class="nav-item"><a routerLink="/sectors-we-cover" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sectors</a></li>
                        <li class="nav-item"><a routerLink="/downloads" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Downloads</a></li> -->
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="flaticon-menu" data-toggle="modal" data-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="option-inner">
                    <div class="others-option justify-content-center d-flex align-items-center">
                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="flaticon-menu" data-toggle="modal" data-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Sidebar Modal -->
<div class="sidebarModal modal right fade" id="sidebarModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal"><i class='bx bx-x'></i></button>

            <div class="modal-body">
                <div class="logo">
                    <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
                </div>

                <div class="sidebar-contact-info">
                    <h2>
                        <a href="tel:+254780058536">(+254) 0113 387 217</a>
                        <span>OR</span>
                        <a href="mailto:info@ocena.org">info@ocena.org</a>
                    </h2>
                </div>

                <ul class="social-list">
                    <li><span>Follow Us On:</span></li>
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i
                                class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i
                                class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i
                                class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block"
                            target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Sidebar Modal -->

<!-- Search Overlay -->
<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-search"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Search Overlay -->