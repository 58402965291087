<app-homesix-main-banner></app-homesix-main-banner>

<app-homesix-featured-services></app-homesix-featured-services>

<app-homesix-about></app-homesix-about>

<app-homesix-services></app-homesix-services>

<section class="what-we-do-area ptb-100">
    <app-what-we-do></app-what-we-do>
</section>

<section class="feedback-area ptb-100">
    <app-feedback></app-feedback>

    <div class="shape16"><img src="assets/img/shape/shape16.png" alt="image"></div>
</section>

<section class="portfolio-area pt-100 pb-70">
    <app-homesix-portfolio></app-homesix-portfolio>
</section>

<section class="partner-area bg-f9f9f9 ptb-70">
    <app-partner></app-partner>
</section>

<section class="team-area-two ptb-100">
    <app-team-style-two></app-team-style-two>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
    <div class="shape17"><img src="assets/img/shape/shape17.png" alt="image"></div>
</section>

<section class="pricing-area bg-f9f9f9 pt-100 pb-70">
    <app-pricing></app-pricing>
</section>

<section class="project-start-area ptb-100">
    <app-project-start></app-project-start>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
</section>

<section class="blog-area bg-f9f9f9 pt-100 pb-70">
    <app-blog></app-blog>
</section>