<div class="container">
    <div class="section-title">
        <span class="sub-title">Our Team</span>
        <h2>We Help to Acheive Your Business Goal</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
    </div>

    <div class="team-slides owl-carousel owl-theme">
        <div class="single-team-member">
            <div class="image">
                <img src="assets/img/team/team-img1.jpg" alt="team-image">

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>

            <div class="content">
                <h3>James Anderson</h3>
                <span>CEO</span>
                <i class="flaticon-share-icon"></i>
            </div>
        </div>

        <div class="single-team-member">
            <div class="image">
                <img src="assets/img/team/team-img2.jpg" alt="team-image">

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>

            <div class="content">
                <h3>Sarah Taylor</h3>
                <span>Marketing Lead</span>
                <i class="flaticon-share-icon"></i>
            </div>
        </div>

        <div class="single-team-member">
            <div class="image">
                <img src="assets/img/team/team-img3.jpg" alt="team-image">

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>

            <div class="content">
                <h3>Taylor Sopia</h3>
                <span>Web Designer</span>
                <i class="flaticon-share-icon"></i>
            </div>
        </div>

        <div class="single-team-member">
            <div class="image">
                <img src="assets/img/team/team-img4.jpg" alt="team-image">

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>

            <div class="content">
                <h3>Harry Steve</h3>
                <span>Web Developer</span>
                <i class="flaticon-share-icon"></i>
            </div>
        </div>

        <div class="single-team-member">
            <div class="image">
                <img src="assets/img/team/team-img5.jpg" alt="team-image">

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>

            <div class="content">
                <h3>Alina Smith</h3>
                <span>Advisor</span>
                <i class="flaticon-share-icon"></i>
            </div>
        </div>

        <div class="single-team-member">
            <div class="image">
                <img src="assets/img/team/team-img6.jpg" alt="team-image">

                <ul class="social-link">
                    <li><a href="https://www.facebook.com/ocena.org/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="https://www.instagram.com/ocena_socialresearch/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="https://twitter.com/ocena_ke" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/ocena-company/?viewAsMember=true" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>

            <div class="content">
                <h3>David Warner</h3>
                <span>Support</span>
                <i class="flaticon-share-icon"></i>
            </div>
        </div>
    </div>
</div>