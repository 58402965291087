<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>About Us</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="about-content">
                    <div class="content">
                        <span class="sub-title">About Ocena</span>
                        <h2>We Help You Meet Your Goals</h2>
                        <p>Ocena provides research services to institutions in the public and the private sectors. At Ocena we focus on developing innovative responses towards
                            social challenges through provision of baseline assessments, midterm and end of project evaluations, operational research, and opinion polls.</p>
                        <p>At Ocena, we work alongside policy-makers and program implementers to design, plan, train, implement, evaluate and report based on the evidence we gather from
                            research, monitoring and evaluation. As a research firm, we are inspired by the pursuit of integrity, excellence, accountability,collaboration and
                             the passion for doing things differently with a lasting positive impact on the individuals, businesses and communities</p>

                             <p>Our vision is to be the most respected, trusted, cost-effective and sought-after research firm by our clients and employees. Ocena
                                 was duly registered in 2014 with the Kenya Registrar of Companies.</p>
                        <!-- <ul class="funfacts-list">
                            <li>
                                <div class="list">
                                    <i class="flaticon-menu-1"></i>
                                    <h3 class="odometer" data-count="3976">00</h3>
                                    <p>Completed Surveys</p>
                                </div>
                            </li>
                            <li>
                                <div class="list">
                                    <i class="flaticon-web-settings"></i>
                                    <h3 class="odometer" data-count="1405452">00</h3>
                                    <p>Responses Received</p>
                                </div>
                            </li>
                            <li>
                                <div class="list">
                                    <i class="flaticon-conversation"></i>
                                    <h3 class="odometer" data-count="402548">00</h3>
                                    <p>Total Panelists</p>
                                </div>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <app-history></app-history> -->

<!-- <section class="team-area ptb-100">
    <app-team-style-one></app-team-style-one>
</section> -->



<!-- <app-testimonials></app-testimonials> -->

<section class="feedback-area ptb-100">
    <app-feedback></app-feedback>

    <div class="shape16"><img src="assets/img/shape/shape16.png" alt="image"></div>
</section>

<section class="partner-area bg-f9f9f9 ptb-70">
    <app-partner></app-partner>
</section>
