<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Gallery</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/blog/blog-img1.jpg">
                        <img src="assets/img/blog/blog-img1.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/blog/blog-img2.jpg">
                        <img src="assets/img/blog/blog-img2.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/blog/blog-img3.jpg">
                        <img src="assets/img/blog/blog-img3.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/blog/blog-img4.jpg">
                        <img src="assets/img/blog/blog-img4.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/blog/blog-img5.jpg">
                        <img src="assets/img/blog/blog-img5.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/blog/blog-img6.jpg">
                        <img src="assets/img/blog/blog-img6.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/blog/blog-img7.jpg">
                        <img src="assets/img/blog/blog-img7.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/blog/blog-img8.jpg">
                        <img src="assets/img/blog/blog-img8.jpg" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-gallery-item">
                    <a data-fancybox="gallery" href="assets/img/blog/blog-img9.jpg">
                        <img src="assets/img/blog/blog-img9.jpg" alt="image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>