import { Component, OnInit } from '@angular/core';
import { ReinitapiService } from '../../../services/reinitapi.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-projects-done',
  templateUrl: './projects-done.component.html',
  styleUrls: ['./projects-done.component.scss']
})
export class ProjectsDoneComponent implements OnInit {
  Form: FormGroup;
  consultancies: any;
  Clients: any;
  constructor(private Data: ReinitapiService) {
    this.Data.getData().subscribe((data) => {
      this.consultancies = data;
    })
    this.Data.getClient().subscribe((ClintData) => {
      this.Clients = ClintData;
    })
  }
  ngOnInit(): void {
  }

}
