import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qualitative-mr',
  templateUrl: './qualitative-mr.component.html',
  styleUrls: ['./qualitative-mr.component.scss']
})
export class QualitativeMrComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
