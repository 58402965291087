import { Component, OnInit } from '@angular/core';
import { ReinitapiService } from '../../../services/reinitapi.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth.guard';
import { Router } from '@angular/router';
@Component({
  selector: 'app-edit-assignment',
  templateUrl: './edit-assignment.component.html',
  styleUrls: ['./edit-assignment.component.scss']
})
export class EditAssignmentComponent implements OnInit {
  Form: FormGroup;
  message: string;
  error: string;
  AssignmentValue: any;
  Year: any;
  MainYear: any;
  Years: object;
  ClientName: any;
  ClientId: any;
  clientId: any;
  Clients: any;
  Id: any;
  public submitted = false;
  public loading = false;
  constructor(private fb: FormBuilder, private Data: ReinitapiService, public authGuard: AuthGuard, private GetQuery: ActivatedRoute, private router: Router) {
    this.Id = Number(this.GetQuery.snapshot.params['id']);
    var Id = Number(this.GetQuery.snapshot.params['id']);
    this.Data.GetAssignmentById(Id).subscribe(res => {
      let AssignmentData = JSON.parse(JSON.stringify(res));
      this.AssignmentValue = AssignmentData.assignment;
      this.Year = AssignmentData.year;
      this.ClientName = AssignmentData.clientName;
      this.ClientId = AssignmentData.clientId;
    })
    this.Data.getClient().subscribe((ClintData) => {
      this.Clients = ClintData;
    })
    var today = new Date();
    var year = today.getFullYear();
    var pastfifthYear = year - 5;
    var allyears = [];
    for (var i = pastfifthYear; i <= pastfifthYear + 10; i++) {
      if (i !== this.Year) {
        allyears.push(i);
      }
    }
    this.Years = allyears;
  }

  get assignment() {
    return this.Form.get('Assignment');
  }
  get year() {
    return this.Form.get('Year');
  }
  get getClientID() {
    return this.Form.get('ClientId');
  }

  ngOnInit(): void {
    this.Form = this.fb.group({
      Year: ['', [Validators.required, Validators.pattern("[0-9]*")]],
      Assignment: ['', [Validators.required]],
      ClientId: ['', [Validators.required, Validators.pattern("[0-9]*")]],
    });
  }

  SetId(e) {
    this.clientId = Number(e.target.value);
  }

  SetYear(e) {
    this.MainYear = e.target.value
  }
  EditAssignment() {
    var ClientId = Number(this.GetQuery.snapshot.params['id']);
    this.submitted = true
    console.log(this.Form.value);
    if (this.clientId == undefined) {
      this.clientId = this.Form.value.ClientId;
    }
    if (this.MainYear == undefined) {
      this.MainYear = this.Form.value.Year;
    }
    if (this.Form.invalid) {
      this.error = "Assignment Not Updated !"
      return
    }
    this.loading = true;
    var AssignmentData = {
      'Id': ClientId,
      'ClientId': Number(this.clientId),
      'Assignment': this.Form.value.Assignment,
      'Year': Number(this.MainYear)
    }
    this.Data.editAssignment(AssignmentData).subscribe(
      res => {
        this.loading = false,
          //this.message = res.success
          this.message = "Assignment Updated Succefully !"
      },
      err => {
        this.loading = false,
          //this.error= err.error.message
          this.error = "Assignment Not Updated !"
      }
    );
  }


}
