import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homesix-featured-services',
  templateUrl: './homesix-featured-services.component.html',
  styleUrls: ['./homesix-featured-services.component.scss']
})
export class HomesixFeaturedServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
