<div class="main-banner">
    <div class="container">
        <div class="banner-area-content">
            <h1 class="wow fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">Digital Marketing Solution</h1>
            <p class="wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">Get all kinds of hi-tech decesion making consulting services using Artificial Intelligence and Machine Learning from one umbrella.</p>
            <form class="wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                <div class="row">
                    <div class="col-lg-5 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" class="form-control" id="website" placeholder="Your Website URL">
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="form-group">
                            <input type="eamil" class="form-control" id="eamil" placeholder="Email">
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="form-group">
                            <button type="submit" class="default-btn">Get Your Report</button>
                        </div>
                    </div>
                </div>
            </form>
            <img src="assets/img/banner-img5.png" class="wow fadeInUp" alt="image" data-wow-delay="300ms" data-wow-duration="1000ms">
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
</div>