<div class="container">
    <div class="section-title">
        <span class="sub-title">Our Blog</span>
        <h2>Our Latest Media</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
    </div>

    <div class="blog-slides owl-carousel owl-theme">
        <div class="single-blog-post">
            <div class="image">
                <a routerLink="/single-blog" class="d-block">
                    <img src="assets/img/blog/blog-img1.jpg" alt="image">
                </a>
            </div>

            <div class="content">
                <h3><a routerLink="/single-blog">Digital Marketing Agency Blogs You Should Read</a></h3>
                <div class="d-flex align-items-center">
                    <img src="assets/img/user1.jpg" alt="image">
                    <div class="info">
                        <h5>David Smith</h5>
                        <span>Jun 21, 2020</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-blog-post">
            <div class="image">
                <a routerLink="/single-blog" class="d-block">
                    <img src="assets/img/blog/blog-img2.jpg" alt="image">
                </a>
            </div>

            <div class="content">
                <h3><a routerLink="/single-blog">Digital Marketing Strategies for Lead Work</a></h3>
                <div class="d-flex align-items-center">
                    <img src="assets/img/user2.jpg" alt="image">
                    <div class="info">
                        <h5>Sarah Taylor</h5>
                        <span>Jun 20, 2020</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-blog-post">
            <div class="image">
                <a routerLink="/single-blog" class="d-block">
                    <img src="assets/img/blog/blog-img3.jpg" alt="image">
                </a>
            </div>

            <div class="content">
                <h3><a routerLink="/single-blog">Agencies Can Successfully Recover From COVID?</a></h3>
                <div class="d-flex align-items-center">
                    <img src="assets/img/user3.jpg" alt="image">
                    <div class="info">
                        <h5>Steven Gibson</h5>
                        <span>Jun 19, 2020</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="single-blog-post">
            <div class="image">
                <a routerLink="/single-blog" class="d-block">
                    <img src="assets/img/blog/blog-img4.jpg" alt="image">
                </a>
            </div>

            <div class="content">
                <h3><a routerLink="/single-blog">Preparing E-Commerce for the Post-COVID</a></h3>
                <div class="d-flex align-items-center">
                    <img src="assets/img/user3.jpg" alt="image">
                    <div class="info">
                        <h5>Steven Gibson</h5>
                        <span>Jun 18, 2020</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>