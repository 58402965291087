<!-- <h1>Dashboard page</h1>
<a routerLink="createClient">Create Client</a>
<br>
<a routerLink="updateClient">Update Client</a> -->


<!DOCTYPE html>
<html lang="en">

<head>
    <title>Quantum Able Bootstrap 4 Admin Dashboard Template</title>

    <!-- HTML5 Shim and Respond.js IE9 support of HTML5 elements and media queries -->
    <!-- WARNING: Respond.js doesn't work if you view the page via file:// -->
    <!--[if lt IE 9]>
    <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
    <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
    <![endif]-->


    <!-- Meta -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="codedthemes">
    <meta name="keywords"
        content=", Responsive, Landing, Bootstrap, App, Template, Mobile, iOS, Android, apple, creative app">
    <meta name="author" content="codedthemes">



</head>

<body class="sidebar-mini fixed">
    <div class="wrapper">
        <div class="loader-bg">
            <div class="loader-bar">
            </div>
        </div>
        <!-- Navbar-->
        <header class="main-header-top hidden-print">
            <a href="index.html" class="logo"><img class="img-fluid able-logo" src="/assets/img/logo_white-full.png"
                    alt="Theme-logo"></a>
            <nav class="navbar navbar-static-top">
                <!-- Sidebar toggle button-->
                <!-- <a href="#!" data-toggle="offcanvas" class="sidebar-toggle"></a>
            <ul class="top-nav lft-nav">
               <li>
                  <a href="#!" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle drop icon-circle drop-image">
                     <i class="ti-files"> </i><span> Files</span>
                  </a>
               </li>
               <li class="dropdown">
                  <a href="#!" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle drop icon-circle drop-image">
                     <span>Dropdown </span><i class=" icofont icofont-simple-down"></i>
                  </a>
                  <ul class="dropdown-menu settings-menu">
                     <li><a href="#">List item 1</a></li>
                     <li><a href="#">List item 2</a></li>
                     <li><a href="#">List item 3</a></li>
                     <li><a href="#">List item 4</a></li>
                     <li><a href="#">List item 5</a></li>
                  </ul>
               </li>
               <li class="dropdown pc-rheader-submenu message-notification search-toggle">
                  <a href="#!" id="morphsearch-search" class="drop icon-circle txt-white">
                     <i class="ti-search"></i>
                  </a>
               </li>
            </ul> -->
                <!-- Navbar Right Menu-->
                <div class="navbar-custom-menu">
                    <!-- <ul class="top-nav">
                  <li class="dropdown notification-menu">
                     <a href="#!" data-toggle="dropdown" aria-expanded="false" class="dropdown-toggle">
                        <i class="icon-bell"></i>
                        <span class="badge badge-danger header-badge">9</span>
                     </a>
                     <ul class="dropdown-menu">
                        <li class="not-head">You have <b class="text-primary">4</b> new notifications.</li>
                        <li class="bell-notification">
                           <a href="javascript:;" class="media">
                              <span class="media-left media-icon">
                    <img class="img-circle" src="assets/assets/images/avatar-1.png" alt="User Image">
                  </span>
                              <div class="media-body"><span class="block">Lisa sent you a mail</span><span class="text-muted block-time">2min ago</span></div>
                           </a>
                        </li>
                        <li class="bell-notification">
                           <a href="javascript:;" class="media">
                              <span class="media-left media-icon">
                    <img class="img-circle" src="assets/assets/images/avatar-2.png" alt="User Image">
                  </span>
                              <div class="media-body"><span class="block">Server Not Working</span><span class="text-muted block-time">20min ago</span></div>
                           </a>
                        </li>
                        <li class="bell-notification">
                           <a href="javascript:;" class="media"><span class="media-left media-icon">
                    <img class="img-circle" src="assets/assets/images/avatar-3.png" alt="User Image">
                  </span>
                                    <div class="media-body"><span class="block">Transaction xyz complete</span><span class="text-muted block-time">3 hours ago</span></div></a>
                        </li>
                        <li class="not-footer">
                           <a href="#!">See all notifications.</a>
                        </li>
                     </ul>
                  </li>
                  <li class="pc-rheader-submenu ">
                     <a href="#!" class="drop icon-circle displayChatbox">
                        <i class="icon-bubbles"></i>
                        <span class="badge badge-danger header-badge">5</span>
                     </a>

                  </li>
                  <li class="pc-rheader-submenu">
                     <a href="#!" class="drop icon-circle" onclick="javascript:toggleFullScreen()">
                        <i class="icon-size-fullscreen"></i>
                     </a>

                  </li>
                  <li class="dropdown">
                     <a href="#!" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle drop icon-circle drop-image">
                        <span><img class="img-circle " src="assets/assets/images/avatar-1.png" style="width:40px;" alt="User Image"></span>
                        <span>John <b>Doe</b> <i class=" icofont icofont-simple-down"></i></span>

                     </a>
                     <ul class="dropdown-menu settings-menu">
                        <li><a href="#!"><i class="icon-settings"></i> Settings</a></li>
                        <li><a href="#"><i class="icon-user"></i> Profile</a></li>
                        <li><a href="#"><i class="icon-envelope-open"></i> My Messages</a></li>
                        <li class="p-0">
                           <div class="dropdown-divider m-0"></div>
                        </li>
                        <li><a href="#"><i class="icon-lock"></i> Lock Screen</a></li>
                        <li><a href="login1.html"><i class="icon-logout"></i> Logout</a></li>

                     </ul>
                  </li>
               </ul> -->

                    <!-- search -->
                    <div id="morphsearch" class="morphsearch">
                        <!-- <form class="morphsearch-form">

                     <input class="morphsearch-input" type="search" placeholder="Search..." />

                     <button class="morphsearch-submit" type="submit">Search</button>

                  </form> -->
                        <!-- <div class="morphsearch-content">
                     <div class="dummy-column">
                        <h2>People</h2>
                        <a class="dummy-media-object" href="#!">
                           <img class="round" src="http://0.gravatar.com/avatar/81b58502541f9445253f30497e53c280?s=50&d=identicon&r=G" alt="Sara Soueidan" />
                           <h3>Sara Soueidan</h3>
                        </a>

                        <a class="dummy-media-object" href="#!">
                           <img class="round" src="http://1.gravatar.com/avatar/9bc7250110c667cd35c0826059b81b75?s=50&d=identicon&r=G" alt="Shaun Dona" />
                           <h3>Shaun Dona</h3>
                        </a>
                     </div>
                     <div class="dummy-column">
                        <h2>Popular</h2>
                        <a class="dummy-media-object" href="#!">
                           <img src="assets/assets/images/avatar-1.png" alt="PagePreloadingEffect" />
                           <h3>Page Preloading Effect</h3>
                        </a>

                        <a class="dummy-media-object" href="#!">
                           <img src="assets/assets/images/avatar-1.png" alt="DraggableDualViewSlideshow" />
                           <h3>Draggable Dual-View Slideshow</h3>
                        </a>
                     </div>
                     <div class="dummy-column">
                        <h2>Recent</h2>
                        <a class="dummy-media-object" href="#!">
                           <img src="assets/assets/images/avatar-1.png" alt="TooltipStylesInspiration" />
                           <h3>Tooltip Styles Inspiration</h3>
                        </a>
                        <a class="dummy-media-object" href="#!">
                           <img src="assets/assets/images/avatar-1.png" alt="NotificationStyles" />
                           <h3>Notification Styles Inspiration</h3>
                        </a>
                     </div>
                  </div> -->
                        <!-- /morphsearch-content -->
                        <!-- <span class="morphsearch-close"><i class="icofont icofont-search-alt-1"></i></span> -->
                    </div>
                    <!-- search end -->
                </div>
            </nav>
        </header>
        <!-- Side-Nav-->
        <aside class="main-sidebar hidden-print ">

            <section class="sidebar" id="sidebar-scroll">
                <ul class="sidebar-menu">
                    <!-- Sidebar Menu-->
                    <!-- <li class="nav-level">--- Navigation</li> -->
                    <!-- <li class="treeview active">
                      <a class="waves-effect waves-dark" href="/">
                         <i class="icon-speedometer"></i><span> Dashboard</span>
                      </a>
                   </li> -->
                    <li class="treeview">
                        <a class="waves-effect waves-dark"><i class="icon-list"></i><span> Client</span><i
                                class="icon-arrow-down"></i></a>
                        <ul class="treeview-menu">
                            <li><a routerLink="/clientList" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="waves-effect waves-dark"><i
                                        class="icon-arrow-right"></i>
                                    Client List </a>
                            </li>
                            <li><a routerLink="/createClient" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="waves-effect waves-dark"><i
                                        class="icon-arrow-right"></i>
                                    Add Client </a>
                            </li>
                        </ul>
                    </li>
                    <li class="treeview active">
                        <a class="waves-effect waves-dark"><i class="icon-book-open"></i><span>
                                Assignment </span><i class="icon-arrow-down"></i></a>
                        <ul class="treeview-menu">
                            <li class="active"><a routerLink="/assignmentList" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="waves-effect waves-dark" href=""><i
                                        class="icon-arrow-right"></i> Assignment
                                    List
                                </a></li>
                            <li><a routerLink="/addAssignment" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="waves-effect waves-dark" href=""><i
                                        class="icon-arrow-right"></i> Add Assignment
                                </a></li>
                        </ul>
                    </li>
                    <li class="treeview">
                        <a (click)="authGuard.LogOut()" style="cursor: pointer;"><i class="icon-logout"></i><span>
                                Logout
                            </span></a>
                    </li>
                </ul>
            </section>
        </aside>


        <div class="content-wrapper pageContents">
            <!-- Container-fluid starts -->
            <div class="btn-page">
                <!-- Container-fluid starts -->
                <div class="container-fluid">
                    <!-- Header start -->
                    <div class="row">
                        <div class="main-header">
                            <h4> Assignments </h4>
                        </div>
                    </div>
                    <!-- Header end -->

                    <!-- Row start -->
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-block button-list">
                                    <section class="cart-area">
                                        <div class="container">
                                            <form class="row">
                                                <div class="cart-table table-responsive">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" style="width: 15%;">
                                                                    Client
                                                                </th>
                                                                <th style="width: 55%;">
                                                                    Assignment</th>
                                                                <th style="width: 15%;"> Year
                                                                </th>
                                                                <th style="width: 15%;">Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let client of Clients">
                                                                <td style="width: 15%;">
                                                                    {{client.clientName}} </td>
                                                                <td colspan="3" style="width: 85%;padding: 0;">
                                                                    <table style="width: 100%;">
                                                                        <tr *ngFor="let consultancy of consultancies"
                                                                            style="width: 85%;">
                                                                            <ng-container
                                                                                *ngIf="client.clientId==consultancy.clientId">
                                                                                <td style="width: 55%;">
                                                                                    {{consultancy.assignment}}</td>
                                                                                <td style="width: 15%;">
                                                                                    {{consultancy.year}}</td>
                                                                                <td style="width: 15%;">
                                                                                    <a routerLink="/editAssignment/{{consultancy.id}}"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Edit
                                                                                    </a>
                                                                                </td>
                                                                            </ng-container>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>


                                                            <!-- <tr *ngFor="let client of Clients" style="font-size: 10px;">
                                                                <td class="product-thumbnail">
                                                                    <p style="color: black; font-weight: 600;">
                                                                        {{client.clientName}}
                                                                    </p>
                                                                </td>&nbsp;&nbsp;
                                                                <div *ngFor="let consultancy of consultancies">
                                                                    <div *ngIf="client.clientId==consultancy.clientId">
                                                                        <td class="product-price">
                                                                            <span class="unit-amount" style="font-size: 13px;">
                                                                                {{consultancy.assignment}}
                                                                            </span>
                                                                        </td>&nbsp;&nbsp;
                                    
                                                                        <td class="product-name">
                                                                            <p>{{consultancy.year}}</p>
                                                                        </td>
                                                                    </div>
                                    
                                                                </div>
                                                            </tr> -->
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </form>
                                        </div>
                                    </section>
                                </div>
                                <!-- end of Raised Button -->
                            </div>
                            <!-- end of card -->
                        </div>
                        <!-- end of col-sm-12 -->
                    </div>
                    <!-- end of row -->

                    <!-- Container-fluid ends -->
                </div>
            </div>
        </div>
    </div>


</body>

</html>