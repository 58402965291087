<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Our Data Collection Methods</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="blog-details-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <!-- <div class="article-image">
                        <img src="assets/img/blog/blog-img1.jpg" alt="image">
                    </div> -->

                    <div class="article-content">

                        <!-- <h3>Digital Marketing Agency Blogs You Should Read</h3> -->

                        <p>Our methodological recommendations are based on your research needs and the questions at hand, rather than our own product portfolio.</p>

                        <h3>Online data collection via our proprietary panel</h3>
                        <p>We are trend setters in Africa, connecting you to over 400,000 consumers while helping you get in touch with them directly through online research.
                            We profile our panelists using detailed background information therefore giving you a wide array of options to choose from according to your needs.
                            We deliver quality, unbiased, social research data tailored to the needs of your business with our unique combination of expertise and reach.</p>

                        <p>We are a corporate member of ESOMAR and as such have fully complied with the set standards for online research.
                            Our panel recruitment is done on a strict double-opt-in (DOI) basis. All potential panelists registering with us automatically receive an
                            email with a link to confirmation registration. Only upon this confirmation, is the registration complete.
                            We also have implemented advanced quality control procedures to eliminate fraudulent panelists.
                            One such measure is to ensure that our clients receive real-time fieldwork reports through online portals.</p>


                        <h3> Personal interviews</h3>

                        <p>thanks to our knowledgeable and experienced team of more than 500 interviewers spread across Africa, we meet your expectations and
                            deliver the highest quality standards in research. We conduct our interviews on the streets, in the shopping centers, at home and the
                            work places of the respondent or in one of our dedicated test facilities.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_plor_posts_thumb">
                        <h3 class="widget-title">Other Services</h3>

                        <article class="item">
                            <a routerLink="/quantitative-market-research" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/quantitative-market-research">Quantitative Social Research</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/qualitative-market-research" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">

                                <h4 class="title usmall"><a routerLink="/qualitative-market-research">Qualitative Social Research</a></h4>
                            </div>

                            <div class="clear"></div>
                        </article>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>
