import { Component, OnInit } from '@angular/core';
import { ReinitapiService } from '../../../services/reinitapi.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth.guard';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  Form: FormGroup;
  LoginData: any;
  constructor(private fb: FormBuilder, private Data: ReinitapiService, private router: Router, private auth: AuthGuard) { }
  public submitted = false;
  public loading = false;
  message: string;
  error: string;



  get username() {
    return this.Form.get('UserName');
  }
  get password() {
    return this.Form.get('Password');
  }

  ngOnInit(): void {
    this.Form = this.fb.group({
      UserName: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\?*=:;/%]*$")]],
      Password: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9!@#$&()\\-`.+,/\?*=:;/%]*$")]],
    });
  }



  login() {
    this.submitted = true
    if (this.Form.invalid) {
      return
    }
    // let username = 'admin';
    // let password = 123456;
    // if (this.Form.value.UserName == username) {
    //   if (this.Form.value.Password == password) {
    //     this.message = "Login Successfull !"
    //     this.router.navigate(['dashboard']);
    //   }
    //   else {
    //     this.error = "Invalid Password !"
    //   }
    // }
    // else {
    //   this.error = "Invalid UserName !"
    // }
    var AdminData = {
      'UserName': this.Form.value.UserName,
      'Password': this.Form.value.Password
    }
    this.Data.login(AdminData).subscribe(
      res => {
        this.loading = false,
          //this.message = res.success
          this.message = "Login Successful !"
        this.Form.reset()
        localStorage.setItem('accessToken', res.token);
        this.router.navigate(['clientList']);
      },
      err => {
        if (err.error.error == "invalid Username") {
          this.loading = false,
            //this.error= err.error.message
            this.error = "invalid Username!"
        }
        else if (err.error.error == "invalid password") {
          this.loading = false,
            //this.error= err.error.message
            this.error = "invalid password"
        }
        else {
          this.loading = false,
            //this.message = res.success
            this.message = "Login Fail !"
          this.Form.reset()
        }

      }
    );
    this.auth.autoLogOut(1800000);
  }
}
