

<div class="container">
    <div class="row align-items-center">
        <!--<div class="col-lg-12 col-md-12">
            <div class="feedback-image">
                 <img src="assets/img/feedback1.webp" alt="image">
                <img src="assets/img/feedback-img3.jpg" alt="image">
                <img src="assets/img/feedback-img3.jpg" alt="image"> 
                <img src="assets/img/feedback-img5.png" alt="image">
            </div>
        </div>-->

        <div class="col-lg-12 col-md-12">
            <div class="feedback-content">

                <div class="feedback-slides owl-carousel owl-theme">
                    <div class="row single-feedback-item">
                        <div class="col-lg-6 col-md-12">
                            <div class="feedback-image">
                                <img src="assets/img/feedback-img1.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <span class="sub-title">Testimonials</span>
                            <h2>What Are Our Clients Saying?</h2>
                            <p> The 3 incidences were very smooth and this  i would attribute to professionalism if the staff but most importantly that at the atrt of the
                                assignment you thouroughly understood the Tors and stayed true to them.This was a painless experience unlike what most external consulties are.Keep up the professionalism and the quality delivery of assigments.</p>
    
                            <div class="client-info">
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="title">
                                        <h3>Dorothy</h3>
                                        <span>Danish Red Cross</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row single-feedback-item">
                        <div class="col-lg-6 col-md-12">
                            <div class="feedback-image">
                                <img src="assets/img/feedback-img2.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <span class="sub-title">Testimonials</span>
                            <h2>What Are Our Clients Saying?</h2>
                            <p>This firm has undertaken quite a number of evaluation tasks for KRCS and have delivered well packaged,professional products.
                                They are quite flexible in in regard to scopes of tasks and have been fully available once contracted.</p>

                            <div class="client-info">
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="title">
                                        <h3>Lydia</h3>
                                        <span>Kenya Red Cross Society</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row single-feedback-item">
                        <div class="col-lg-6 col-md-12">
                            <div class="feedback-image">
                                <img src="assets/img/feedback-img3.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <span class="sub-title">Testimonials</span>
                            <h2>What Are Our Clients Saying?</h2>
                            <p>You delivered on your assignment with lots of professionalism. The willingness to listen and address customer needs define your client centered
                                approach of service delivery. It was a good experience working with you and I would recommend you to someone who wants to draw learnings from their work and use them to design both effective and efficient interventions.</p>
                            <div class="client-info">
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="title">
                                        <h3>Geoffrey</h3>
                                            <span>Save the Children</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row single-feedback-item">
                        <div class="col-lg-6 col-md-12">
                            <div class="feedback-image">
                                <img src="assets/img/feedback-img4.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <span class="sub-title">Testimonials</span>
                            <h2>What Are Our Clients Saying?</h2>
                            <p>Working with you is the best experience ever.You understand your work well,very committed and very professional in all your undertakings.</p>
                            <div class="client-info">
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="title">
                                        <h3>Sharon</h3>
                                            <span>KETRACO</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row single-feedback-item">
                        <div class="col-lg-6 col-md-12">
                            <div class="feedback-image">
                                <img src="assets/img/feedback-img5.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <span class="sub-title">Testimonials</span>
                            <h2>What Are Our Clients Saying?</h2>
                            <p>It was a high pressured assignment that the consultant delivered to satisfaction.</p>
                            <div class="client-info">
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/user3.jpg" alt="image">
                                    <div class="title">
                                        <h3>Fidelina</h3>
                                            <span>Amref Health Arica in Kenya</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
