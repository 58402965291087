<section class="about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about-img2.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="text">
                        <span class="sub-title">About Us</span>
                        <h2>Enjoy Full-Service Digital Marketing Expertise</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <ul class="features-list">
                            <li><i class="flaticon-speed"></i> If Google can’t crawl your site, it’s not going to rank – but that doesn’t mean avoiding Javascript.</li>
                            <li><i class="flaticon-speed"></i> If Google can’t crawl your site, it’s not going to rank – but that doesn’t mean avoiding Javascript.</li>
                            <li><i class="flaticon-speed"></i> If Google can’t crawl your site, it’s not going to rank – but that doesn’t mean avoiding Javascript.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="shape17"><img src="assets/img/shape/shape17.png" alt="image"></div>
</section>