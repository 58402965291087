<!-- <h1>Dashboard page</h1>
<a routerLink="createClient">Create Client</a>
<br>
<a routerLink="updateClient">Update Client</a> -->


<!DOCTYPE html>
<html lang="en">

<head>
    <title>Quantum Able Bootstrap 4 Admin Dashboard Template</title>

    <!-- HTML5 Shim and Respond.js IE9 support of HTML5 elements and media queries -->
    <!-- WARNING: Respond.js doesn't work if you view the page via file:// -->
    <!--[if lt IE 9]>
    <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
    <script src="https://oss.maxcdn.com/libs/respond.js/1.4.2/respond.min.js"></script>
    <![endif]-->


    <!-- Meta -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="codedthemes">
    <meta name="keywords"
        content=", Responsive, Landing, Bootstrap, App, Template, Mobile, iOS, Android, apple, creative app">
    <meta name="author" content="codedthemes">



</head>

<body class="sidebar-mini fixed">
    <div class="wrapper">
        <div class="loader-bg">
            <div class="loader-bar">
            </div>
        </div>
        <!-- Navbar-->
        <header class="main-header-top hidden-print">
            <a href="index.html" class="logo"><img class="img-fluid able-logo" src="/assets/img/logo_white-full.png"
                    alt="Theme-logo"></a>
            <nav class="navbar navbar-static-top">

            </nav>
        </header>
        <!-- Side-Nav-->
        <aside class="main-sidebar hidden-print ">

            <section class="sidebar" id="sidebar-scroll">
                <!-- Sidebar Menu-->
                <ul class="sidebar-menu">
                    <!-- <li class="nav-level">--- Navigation</li> -->
                    <!-- <li class="treeview active">
                      <a class="waves-effect waves-dark" href="/">
                         <i class="icon-speedometer"></i><span> Dashboard</span>
                      </a>
                   </li> -->
                    <li class="treeview active">
                        <a class="waves-effect waves-dark"><i class="icon-list"></i><span> Client</span><i
                                class="icon-arrow-down"></i></a>
                        <ul class="treeview-menu">
                            <li class="active"><a routerLink="/clientList" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="waves-effect waves-dark"><i
                                        class="icon-arrow-right"></i>
                                    Client List </a>
                            </li>
                            <li><a routerLink="/createClient" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="waves-effect waves-dark"><i
                                        class="icon-arrow-right"></i>
                                    Add Client </a>
                            </li>
                        </ul>
                    </li>
                    <li class="treeview">
                        <a class="waves-effect waves-dark"><i class="icon-book-open"></i><span>
                                Assignment </span><i class="icon-arrow-down"></i></a>
                        <ul class="treeview-menu">
                            <li><a routerLink="/assignmentList" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="waves-effect waves-dark" href=""><i
                                        class="icon-arrow-right"></i> Assignment
                                    List
                                </a></li>
                            <li><a routerLink="/addAssignment" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="waves-effect waves-dark" href=""><i
                                        class="icon-arrow-right"></i> Add Assignment
                                </a></li>
                        </ul>
                    </li>
                    <li class="treeview">
                        <a (click)="authGuard.LogOut()" style="cursor: pointer;"><i class="icon-logout"></i><span>
                                Logout
                            </span></a>
                    </li>
                </ul>
            </section>
        </aside>


        <div class="content-wrapper pageContents">
            <!-- Container-fluid starts -->
            <div class="btn-page">
                <!-- Container-fluid starts -->
                <div class="container-fluid">
                    <!-- Header start -->
                    <div class="row">
                        <div class="main-header">
                            <h4> Edit Client </h4>
                        </div>
                    </div>
                    <!-- Header end -->

                    <!-- Row start -->
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-block button-list">
                                    <div class="col-lg-12 adminLoginWrap">
                                        <div class="contact-form">
                                            <!-- <h3>Create Client</h3> -->
                                            <form [formGroup]="Form" (ngSubmit)="EditClient()">
                                                <div *ngIf="message"
                                                    class="alert alert-success alert-dismissible fade show"
                                                    role="alert">
                                                    {{ message }}
                                                    <button type="button" class="close" data-dismiss="alert"
                                                        aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div *ngIf="error"
                                                    class="alert alert-danger alert-dismissible fade show" role="alert">
                                                    {{ error }}
                                                    <button type="button" class="close" data-dismiss="alert"
                                                        aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-6">
                                                        <label>Client Name <span class="required"></span></label>
                                                        <div class="form-group">
                                                            <input type="text" formControlName="ClientName"
                                                                class="form-control" id="ClientName" required
                                                                data-error="Please enter client name"
                                                                placeholder="Client Name" value="{{ClientName}}
                                                                " [ngModel]="ClientName">
                                                            <div class="form-control-feedback"
                                                                *ngIf="clientname.errors && (clientname.dirty || clientname.touched)">
                                                                <p *ngIf="clientname.errors.required">
                                                                    <span style="color: red;">This field is
                                                                        required</span>
                                                                </p>
                                                                <p *ngIf="clientname.errors.pattern">
                                                                    <span style="color: red;">Invalid name</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 text-right">
                                                        <button type="submit" class="default-btn" [disabled]="loading">
                                                            <span class="spinner-border spinner-border-sm"
                                                                *ngIf="loading" role="status" aria-hidden="true"></span>
                                                            Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <!-- end of Raised Button -->
                            </div>
                            <!-- end of card -->
                        </div>
                        <!-- end of col-sm-12 -->
                    </div>
                    <!-- end of row -->

                    <!-- Container-fluid ends -->
                </div>
            </div>
        </div>
    </div>


</body>

</html>