<!-- <section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Admin Login</span>

        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form #adminLogin="ngForm" (ngSubmit)="login(adminLogin.value)">
                        <label>UserName</label>
                        <input type="text" ngModel name="username" placeholder="UserName" />
                        <br>
                        <label>Password</label>
                        <input type="password" ngModel name="password" placeholder="Password" />
                        <br>
                        <button>Login</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->
<div class="col-lg-4 offset-lg-4 col-md-8 offset-md-2 adminLoginWrap">
    <div class="contact-form">
        <h3>Admin Login</h3>
        <form [formGroup]="Form" (ngSubmit)="login()">
            <div *ngIf="message" class="alert alert-success alert-dismissible fade show" role="alert">
                {{ message }}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
                {{ error }}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-6">
                    <div class="form-group">
                        <input type="text" formControlName="UserName" class="form-control" id="UserName" required
                            data-error="Please enter username" placeholder="UserName">
                        <div class="form-control-feedback"
                            *ngIf="username.errors && (username.dirty || username.touched)">
                            <p *ngIf="username.errors.required">
                                <span style="color: red;">This field is required</span>
                            </p>
                            <p *ngIf="username.errors.pattern">
                                <span style="color: red;">Invalid name</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-6">
                    <div class="form-group">
                        <input type="password" formControlName="Password" class="form-control" id="Password" required
                            data-error="Please enter password" placeholder="Password">
                        <div class="form-control-feedback"
                            *ngIf="password.errors && (password.dirty ||     password.touched)">
                            <p *ngIf="password.errors.required">
                                <span style="color: red;">This field is required</span>
                            </p>
                            <p *ngIf="password.errors.pattern">
                                <span style="color: red;">Invalid Password</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 text-right">
                    <button type="submit" class="default-btn" [disabled]="loading">
                        <span class="spinner-border spinner-border-sm" *ngIf="loading" role="status"
                            aria-hidden="true"></span> Login</button>
                </div>
            </div>
        </form>
    </div>
</div>