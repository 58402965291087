import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homesix-portfolio',
  templateUrl: './homesix-portfolio.component.html',
  styleUrls: ['./homesix-portfolio.component.scss']
})
export class HomesixPortfolioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
