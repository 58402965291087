<section class="how-its-work-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="how-its-work-content">
                    <span class="sub-title">How It's Work</span>
                    <h2>Our Working Process</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                    <div class="inner-box">
                        <div class="single-item">
                            <div class="count-box">
                                01
                            </div>
                            <h3>Planning</h3>
                            <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                        </div>

                        <div class="single-item">
                            <div class="count-box">
                                02
                            </div>
                            <h3>Research</h3>
                            <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                        </div>

                        <div class="single-item">
                            <div class="count-box">
                                03
                            </div>
                            <h3>Results</h3>
                            <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="how-its-work-image">
                    <img src="assets/img/how-its-work.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>