import { Component, OnInit } from '@angular/core';
import { AuthGuard } from 'src/app/services/auth.guard';
import { ReinitapiService } from 'src/app/services/reinitapi.service';
// import "@angular/assets/assets/js/main.js";

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
  // styleUrls: ['../../../../assets/assets/icon/themify-icons/themify-icons.css']
})
export class AdminDashboardComponent implements OnInit {
  consultancies: any;
  Clients: any;
  constructor(public authGuard: AuthGuard, public services: ReinitapiService) {
    this.services.getData().subscribe((data) => {
      this.consultancies = data;
    })
    this.services.getClient().subscribe((ClintData) => {
      this.Clients = ClintData;
    })
  }
  ngOnInit(): void {
  }

}

