<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>End of Project Valuation</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content">
                    <span class="sub-title"></span>
                    <h2>End of Project Valuation</h2>
                    <p>Also known as the summative evaluation, endline evaluation or the project completion evaluation. At Ocena, we establish project outputs and
                        immediate outcomes, with results of the evaluation compared to the results at baseline and midline. We use the evaluation findings to inform
                        implementors and stakeholders on the project success and is important for documenting success stories and lessons learnt.
                    </p>
                    <!-- <div class="features-text">
                        <h4>Our Qualitative Social Research Methods</h4>
                        <p>We use wide range of qualittative social research methods and techniques that result into quality responses.</p>
                    </div> -->
                </div>
            </div>

            <!-- <div class="overview-image">
                <div class="image">
                    <img src="assets/img/services/services-img2.png" alt="image">
                </div>
            </div> -->
        </div>
    </div>
</section>

<section class="project-start-area ptb-100">
    <app-project-start></app-project-start>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape13.png" alt="image"></div>
</section>
