<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Downloads</h1>
        </div>
    </div>

    <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<section class="blog-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a href="#" class="d-block">
                            <img src="assets/downloads/panel-book.png" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <h3><a href="#">Ocena Panel Book</a></h3>
                        <div class="d-flex">
                            <div class="info">
                                <div class="col-lg-12 col-md-12">
                                    <a href="assets/downloads/OTg1ODE1Ni1wM0dVckpHcGhBPT0tOTUzNTYyOA.pdf" target="_blank" class="default-btn btn-block"><i class="left-icon flaticon-next-button"></i> Download Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a href="#" class="d-block">
                            <img src="assets/downloads/esomar.png" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/single-blog">ESOMAR 28 Questions</a></h3>
                        <div class="d-flex align-items-center">
                            <div class="info">
                                <div class="col-lg-12 col-md-12">
                                    <a href="assets/downloads/NTM3MDgwMi1wM0dVckpHb2pRPT0tNTk4NjY2Mg.pdf" target="_blank" class="default-btn btn-block"><i class="left-icon flaticon-next-button"></i> Download Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="image">
                        <a href="#" class="d-block">
                            <img src="assets/downloads/cert.jpeg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <h3><a href="#">ESOMAR 28 Certificate</a></h3>
                        <div class="d-flex align-items-center">
                            <div class="info">
                                <div class="col-lg-12 col-md-12">
                                    <i class="fas fa-download"></i>
                                    <a href="assets/downloads/MzIyNDU3OS1wM0dVcXBpaml3PT0tNjYzNDc4NA.pdf" target="_blank" class="default-btn btn-block"><i class="left-icon flaticon-next-button"></i> Download Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
